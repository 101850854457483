@import 'highlight.js/scss/monokai-sublime.scss';

/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}

:root {
  background-color: #1e1e1e;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SF Mono', SFMono-Regular, ui-monospace, Menlo, Consolas, monospace;
  font-size: 11px;
  line-height: 12px;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-size: 11px;
  line-height: 12px;
  color: #fff;
}

pre {
  padding: 6px 6px 0;
  margin: 0;

  b {
    font-weight: 600;
  }

  * {
    &.b,
    &[b] {
      color: blue;
    }

    &.t,
    &[t] {
      color: #40bec8;
    }

    &.pu,
    &[pu] {
      color: #6b42f7;
    }

    &.p,
    &[p] {
      color: #d746d6;
    }

    &.g,
    &[g] {
      color: #41c731;
    }

    &.gr,
    &[gr] {
      color: #787778;
    }

    &.r,
    &[r] {
      color: #cd482e;
    }
  }

  .post {
    white-space-collapse: initial;
    text-wrap: initial;
    display: flex;
    gap: 6px;
    flex-direction: column;
    max-width: 600px;
    padding: 12px 12px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.2em;
      line-height: 1.2;
      padding: 8px 0 0;
    }

    img {
      width: inherit;
      margin: 12px 0;
      max-width: 800px;
      height: auto;
    }

    ul {
      padding-left: 22px;
    }

    pre {
      padding: 12px;
      border: 1px solid;
      margin: 12px;
    }

    hr {
      width: calc(100% - 24px);
      height: 1px;
      border: 0;
      background: #fff;
      margin: 12px;
    }
  }
}
